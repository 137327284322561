#CitizenPortal header{
    background-color: #fff;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.05);
}
#CitizenPortal header img{
    max-height: 100%;
    max-height: 40px;
}
#CitizenPortal header svg{
    color: #000;
    height: 18px;
}
#CitizenPortal header .lastseen{
    font-size: 15px;
}
#CitizenPortal .nav{
    align-items: center;
}

#CitizenPortal .MenuBar{
    width: 303px;
}
#CitizenPortal .PageContent{
    width: calc(100% - 303px);
    border-right: 1px solid rgb(222, 222, 222);
}
#CitizenPortal .mainDiv{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(222, 222, 222);
    margin-bottom: 30px;
}
#CitizenPortal .list-group,
.CitizenMenu{
    border-radius: 0px;
}
#CitizenPortal .list-group-item,
.CitizenMenu .list-group-item{
    border-left: 0px;
    border-right: 0px;
    border-color:  rgb(222, 222, 222);
    font-size: 18px;
    border-radius: 0px;
    padding: 0px;
    font-weight: 700;
    transition: 0.3s;
}
#CitizenPortal .list-group-item a,
.CitizenMenu .list-group-item a{
    display: block;
    padding: 25px 40px 25px 0px;
}
#CitizenPortal .list-group-item:hover ,
.CitizenMenu .list-group-item:hover{
    background-color: rgba(33,126,190,0.06);
    border-right: 4px solid #217EBE;
}
#CitizenPortal .pageTitle{
    border-bottom: 1px solid #f7f7f7;
}
@media (min-width: 992px){
    #CitizenPortal .navbar-collapse {
        justify-content: space-between;
    }
}
@media(max-width:1400px) {
    #CitizenPortal .PageContent {
        width: 100%;
    }
    #CitizenPortal .container {
        width: 100vw !important;
        max-width: 100vw !important;
    }
}
@media(min-width:1500px) {
    #CitizenPortal .container {
        max-width: 90vw !important;
    }
}

html[dir="ltr"] .dropdown-menu[data-bs-popper]{
    left: 0px;
    right: inherit;
}

html[dir="ltr"] #CitizenPortal .PageContent {
    border-right: 0px;
    border-left: 1px solid rgb(222, 222, 222);
}

html[dir="ltr"] #CitizenPortal .list-group-item a,
html[dir="ltr"] .CitizenMenu .list-group-item a{
    padding: 25px 0px 25px 40px;
}
html[dir="ltr"] #CitizenPortal .list-group-item:hover ,
html[dir="ltr"] .CitizenMenu .list-group-item:hover{
    border-left: 4px solid #217EBE;
    border-right: 0px;
}
.pageTitle{
    font-size: 20px;
    font-weight: 700;
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 500ms both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 500ms both;
}



.PageContent{
    -webkit-animation: slit-in-horizontal 0.6s ease-out both;
    animation: slit-in-horizontal 0.6s ease-out both;
}
.AttachmentCanvas{
    width: 70vw;
    border-radius: 0px 30px 30px 0px;
    padding: 10px 20px;
    box-shadow: rgba(0,0,0,0.11) 0px 6px 12px 0px;
}
@media (max-width: 1200px) {
    .AttachmentCanvas {
        width: 80vw;
    }
}
@media (max-width: 768px){
    .AttachmentCanvas {
        width: calc(100vw - 20px);
    }
}
.AttachmentCanvas .Iframe{
    width: calc(100% - 130px);
    height: 100%;
}