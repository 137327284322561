#root{
    height: 100%;
}
.font400{
    font-weight: 400;
}
.font500{
     font-weight: 500;
}
.font700{
    font-weight: 700;
}
.font900{
    font-weight: 900;
}
.h100{
    height: 100vh
}
.w100{
    width: 100vw
}
.w-100{
    width: 100%
}
.bold{
    font-weight: 700 !important;
}
a{
    text-decoration: none;
    color: inherit;
}
a:focus,
a:hover{
    color: inherit !important;
}
input:focus,select:focus,textarea:focus,button:focus,.form-control:focus{
    border: 1px solid #537895;
    box-shadow: 0 2px 5px 0 rgba(83, 120, 149,0.26);
}
.DarkBtn {
    background-color: #537895;
    background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);    box-shadow: 0 12px 26px 0 rgba(3,3,3,0.26);
    border-radius: 10px;
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #FFFFFF;
    text-align: center;
    display: block;
    padding: 10px;
    transition: .2s;
    width: 100%;
    border: 0px;
    transition: 300ms;
}

.form-control.is-valid, .was-validated .form-control:valid,.form-select:is-valid {
    border-color: #537895;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='green'  fill-opacity='0.4' stroke-opacity='0.8' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.underline,
.underlines a{
    color: inherit;
    text-decoration: none;
    display: inline-block;
    position: relative;
}
.underline:after,
.underlines a:after{
    content: " ";
    position: absolute;
    bottom: -3px;
    height: 2px;
    background-image: linear-gradient(315deg, #ffb700 0% , #ff7b00 50%, #ffb700 100%);
    right: 50%;
    width: 0%;
    transition: .2s;
}
.underline h1,.underline h2,.underline h3,.underline h4,.underline h5,.underline h6{
    color: #09203f;
}
.underline:hover::after,
.underlines a:hover::after{
    width: 100%;
    right: 0px;
 }
.underline:hover,
.underlines a:hover{
    color: inherit;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-bottom: 0;
    border-left: 0.2em solid transparent;
}

.dropdown-item:not(:last-child){
    border-bottom: 1px solid rgba(0,0,0,0.04);
}
.dropdown-item{
    transition: 0.3s;
}
.dropdown-item:focus,
.dropdown-item:hover{
    background-color: #09203f;
    color: #fff !important;
}
.dropdown-menu{
    border-radius: 0px;
    border: 1px solid #f7f7f7;
    box-shadow: 0px 5px 10px rgba(11,41,99,0.23);
}
.nav-item,
.nav-item:hover svg{
    transition: 0.3s;
}
.nav-item:hover{
    background-color: #f8f8f8;
    border-radius: 5px;
}

.navbar-nav{
    align-items: center;
}
.offcanvas-end,
.offcanvas-start{
    width: 320px;
}
.navbar-toggler{
    border: 0px;
    background-color: #f7f7f7;
    border-radius: 0px;
    min-height: 50px;
}
button:not(".btn") {
    border: 0px !important;
    box-shadow: 0px 0px 0px !important;;
}
.rdt_TableHeadRow {
    font-size: 14px !important;
    font-weight: bold;
    background-color: rgba(93,203,255,0.05) !important;

}
.table thead tr{
    background-color: rgba(93,203,255,0.05) !important;
    font-size: 14px;
}
.rdt_TableRow{
    font-size: 14px !important;
}
.rdt_TableRow:hover{
    background-color:rgba(11,40,97,0.02);
 }
html[dir="ltr"] .feather-chevron-left{
    transform: rotate(181deg);
}
@-webkit-keyframes slit-in-horizontal {
    0% {
        -webkit-transform: translateZ(-800px) rotateX(90deg);
        transform: translateZ(-800px) rotateX(90deg);
        opacity: 0;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateX(87deg);
        transform: translateZ(-160px) rotateX(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateX(0);
        transform: translateZ(0) rotateX(0);
    }
}
@keyframes slit-in-horizontal {
    0% {
        -webkit-transform: translateZ(-800px) rotateX(90deg);
        transform: translateZ(-800px) rotateX(90deg);
        opacity: 0;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateX(87deg);
        transform: translateZ(-160px) rotateX(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateX(0);
        transform: translateZ(0) rotateX(0);
    }
}

@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
.expand{
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 500ms both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 500ms both;
}
.horizontal{
    -webkit-animation: slit-in-horizontal 0.6s ease-out both;
    animation: slit-in-horizontal 0.6s ease-out both;
}
.box-shadow-0{
    box-shadow: 0px 0px 0px !important;
}

.btn-new{
    color: #3F3F3F;
    padding: 5px 10px;
    line-height: 1;
}
.btn-new:hover{
    color: #fff;
}
.btn-new.bg-yallow{
    color: #3F3F3F;
}
.newtable svg{
    height: 17px;
    width: 17px;
}

.DarkBtn-sm{
    background-color: #537895;
    background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);    box-shadow: 0 12px 26px 0 rgba(3,3,3,0.26);
    border-radius: 5px;
    font-size: 15px;
    color: #FFFFFF !important;
    text-align: center;
    display: inline-block;
    line-height: 1;
    padding: 7px 10px;
    transition: .2s;
    font-weight: normal;;
    border: 0px;
    transition: 300ms;
}

.DarkBtn2-sm{
    background-color: #537895;
    background-image: linear-gradient(315deg, #D7060A 0%, #333 74%);
    box-shadow: 0 12px 26px 0 rgba(3,3,3,0.26);
    border-radius: 5px;
    font-size: 15px;
    color: #FFFFFF !important;
    text-align: center;
    display: inline-block;
    line-height: 1;
    padding: 7px 10px;
    transition: .2s;
    font-weight: normal;;
    border: 0px;
    transition: 300ms;
}
svg.color1{
    fill: rgba(255,146,120,0.23);
    stroke: #ff9278;
}
svg.color2{
    fill: rgba(5,171,224,0.1);
    stroke: #8F96A9;
}
svg.color3{
    fill: rgba(255,146,120,0.27);
    stroke: #ff9278;
}

html[dir="ltr"] .border-end{
   border-right: 1px solid #dee2e6!important;;
    border-left: 0px !important;;
}

html[dir="ltr"] .border-start{
    border-right: 0px !important;
    border-left: 1px solid #dee2e6!important;;
}
.border-dash{
    border-style: dashed;
}
.btn-rounded{
    border-radius: 100%;
}


.animated {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }

@-webkit-keyframes e-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px; }
    100% {
        opacity: 1;
        margin-top: 0; } }

@keyframes e-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px; }
    100% {
        opacity: 1;
        margin-top: 0; } }

.fadeInUp {
    -webkit-animation-name: e-fadeInUp;
    animation-name: e-fadeInUp; }
.form-select{
    line-height: 1;
}
.form-control ,
.form-select{
    height: auto;
    border: 1px solid #bfc9d4 !important;
    color: #3b3f5c !important;
    font-size: 16px !important;
    padding: 8px 10px;
    height: calc(1.4em + 1.4rem + 2px);
    /*
    padding: .75rem 1.25rem;

     */
    border-radius: 6px;
}
[dir='ltr'] .form-select{
    background-position: right 0.75rem center;
}
[dir='ltr'] .btn-close{
    position: absolute;;
    right: 10px;
}
[dir='ltr'] .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu{
    right: 0px !important;
    left: inherit;
}
.form-control[type="range"] {
    padding: 0; }
.form-control:focus,
.form-select:focus{
    box-shadow: 0 0 5px 2px rgba(194, 213, 255, 0.619608);
    border-color: #4361ee !important;
    color: #3b3f5c !important; }
.form-control::-webkit-input-placeholder, .form-control::-ms-input-placeholder, .form-control::-moz-placeholder {
    color: #bfc9d4;
    font-size: 15px; }
.form-control:focus::-webkit-input-placeholder, .form-control:focus::-ms-input-placeholder, .form-control:focus::-moz-placeholder {
    color: #d3d3d3;
    font-size: 15px; }
.form-control.form-control-lg {
    font-size: 19px;
    padding: 11px 20px; }
.form-control.form-control-sm {
    /* padding: 7px 16px;
    font-size: 13px; */
    display: block;
    width: 100%;
    height: 2.714rem;
    padding: .438rem 1rem;
    font-size: 1rem;
    line-height: 1.45;
    /* color: #6E6B7B; */
    /* background-color: #FFF; */
    /* background-clip: padding-box; */
    /* border: 1px solid #D8D6DE; */
    /* border-radius: .357rem; */
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.form-control.border-0 ,
.form-select.border-0{
    border: 0px !important;
}
.badge {
    font-weight: 600;
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out; }
.badge:hover {
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); }
.badge.badge-enabled {
    background-color: #1abc9c;
    color: #fff; }
.badge.badge-disable {
    background-color: #e7515a;
    color: #fff; }

.badge-primary {
    color: #fff;
    background-color: #4361ee; }

.badge-info {
    color: #fff;
    background-color: #2196f3; }

.badge-success {
    color: #fff;
    background-color: #1abc9c; }

.badge-danger {
    color: #fff;
    background-color: #e7515a; }

.badge-warning {
    color: #fff;
    background-color: #e2a03f; }

.badge-dark {
    color: #fff;
    background-color: #3b3f5c; }

.badge-secondary {
    background-color: #805dca; }

.outline-badge-primary {
    color: #4361ee;
    background-color: transparent;
    border: 1px solid #4361ee; }

.outline-badge-info {
    color: #2196f3;
    background-color: transparent;
    border: 1px solid #2196f3; }

.outline-badge-success {
    color: #1abc9c;
    background-color: transparent;
    border: 1px solid #1abc9c; }

.outline-badge-danger {
    color: #e7515a;
    background-color: transparent;
    border: 1px solid #e7515a; }

.outline-badge-warning {
    color: #e2a03f;
    background-color: transparent;
    border: 1px solid #e2a03f; }

.outline-badge-dark {
    color: #3b3f5c;
    background-color: transparent;
    border: 1px solid #3b3f5c; }

.outline-badge-secondary {
    color: #805dca;
    background-color: transparent;
    border: 1px solid #805dca; }

.outline-badge-primary:focus, .outline-badge-primary:hover {
    background-color: #eaf1ff;
    color: #4361ee; }

.outline-badge-secondary:focus, .outline-badge-secondary:hover {
    color: #805dca;
    background-color: #f3effc; }

.outline-badge-success:focus, .outline-badge-success:hover {
    color: #1abc9c;
    background-color: #ddf5f0; }

.outline-badge-danger:focus, .outline-badge-danger:hover {
    color: #e7515a;
    background-color: #fff5f5; }

.outline-badge-warning:focus, .outline-badge-warning:hover {
    color: #e2a03f;
    background-color: #fff9ed; }

.outline-badge-info:focus, .outline-badge-info:hover {
    color: #2196f3;
    background-color: #e7f7ff; }

.outline-badge-dark:focus, .outline-badge-dark:hover {
    color: #3b3f5c;
    background-color: #e3e4eb; }
.badge[class*="link-badge-"] {
    cursor: pointer; }

.link-badge-primary {
    color: #4361ee;
    background-color: transparent;
    border: 1px solid transparent; }

.link-badge-info {
    color: #2196f3;
    background-color: transparent;
    border: 1px solid transparent; }

.link-badge-success {
    color: #1abc9c;
    background-color: transparent;
    border: 1px solid transparent; }

.link-badge-danger {
    color: #e7515a;
    background-color: transparent;
    border: 1px solid transparent; }

.link-badge-warning {
    color: #e2a03f;
    background-color: transparent;
    border: 1px solid transparent; }

.link-badge-dark {
    color: #3b3f5c;
    background-color: transparent;
    border: 1px solid transparent; }

.link-badge-secondary {
    color: #805dca;
    background-color: transparent;
    border: 1px solid transparent; }

.link-badge-primary:focus, .link-badge-primary:hover {
    color: #4361ee;
    background-color: transparent; }

.link-badge-secondary:focus, .link-badge-secondary:hover {
    color: #6f51ea;
    background-color: transparent; }

.link-badge-success:focus, .link-badge-success:hover {
    color: #2ea37d;
    background-color: transparent; }

.link-badge-danger:focus, .link-badge-danger:hover {
    color: #e7515a;
    background-color: transparent; }

.link-badge-warning:focus, .link-badge-warning:hover {
    color: #dea82a;
    background-color: transparent; }

.link-badge-info:focus, .link-badge-info:hover {
    color: #009eda;
    background-color: transparent; }

.link-badge-dark:focus, .link-badge-dark:hover {
    color: #454656;
    background-color: transparent; }



.btn {
    padding: 0.4375rem 1.25rem;
    text-shadow: none;
    font-size: 14px;
    color: #3b3f5c;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #f1f2f3;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out; }
.btn svg {
    width: 14px;
    height: 14px;
    vertical-align: middle; }
.btn.rounded-circle {
    height: 35px;
    width: 35px;
    padding: 6px 6px;
    line-height: 1;
}
.btn:hover, .btn:focus {
    color: #3b3f5c;
    background-color: #f1f2f3;
    border-color: #d3d3d3;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 #e0e6ed, 0 2px 10px 0 #e0e6ed;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); }

.btn-group .btn:hover, .btn-group .btn:focus {
    -webkit-transform: none;
    transform: none; }

.btn.disabled, .btn.btn[disabled] {
    background-color: #f1f2f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.13); }

.btn.disabled:hover, .btn.btn[disabled]:hover {
    cursor: not-allowed; }

.btn .caret {
    border-top-color: #0e1726;
    margin-top: 0;
    margin-right: 3px;
    vertical-align: middle; }

.btn + .caret, .btn + .dropdown-toggle .caret {
    margin-right: 0; }

.btn-group > .btn, .btn-group .btn {
    padding: 8px 14px; }

.btn-group-lg > .btn, .btn-group-lg .btn {
    font-size: 1.125rem; }

.btn-group-lg > .btn {
    padding: .625rem 1.5rem;
    font-size: 16px; }

.btn-lg {
    padding: .625rem 1.5rem;
    font-size: 16px; }

.btn-group > .btn.btn-lg, .btn-group .btn.btn-lg {
    padding: .625rem 1.5rem;
    font-size: 16px; }

.btn-group-lg > .btn, .btn-group-lg .btn {
    font-size: 1.125rem; }

.btn-group-sm > .btn, .btn-sm {
    font-size: 0.6875rem; }

.btn-group > .btn.btn-sm, .btn-group .btn.btn-sm {
    font-size: 0.6875rem; }

.btn-group .dropdown-menu {
    border: none;
    border: 1px solid #e0e6ed;
    z-index: 1028;
    box-shadow: none;
    padding: 10px;
    padding: .35rem 0;
    top: 0 !important;
    left: auto;
    border-radius: 8px;
    right: auto; }
.btn-group .dropdown-menu a.dropdown-item {
    border-radius: 5px;
    width: 100%;
    padding: 6px 17px;
    clear: both;
    font-weight: 500;
    color: #030305;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 13px; }
.btn-group .dropdown-menu a.dropdown-item:hover {
    color: #2196f3; }
.btn-group .dropdown-menu a.dropdown-item svg {
    cursor: pointer;
    color: #888ea8;
    margin-left: 6px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    fill: rgba(0, 23, 55, 0.08); }
.btn-group .dropdown-menu a.dropdown-item:hover svg {
    color: #4361ee; }

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
    border: none;
    border: 1px solid #e0e6ed;
    z-index: 899;
    box-shadow: none;
    padding: 10px;
    padding: .35rem 0;
    transition: top 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s, visibility 0.2s ease-in-out 0s;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    transform: none !important;
    top: 0 !important;
    left: 0;
    right: auto !important;
    border-radius: 8px; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.right {
    left: 0;
    right: auto !important; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.left {
    left: 0;
    right: auto !important; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    top: 21px !important; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item {
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 6px 17px;
    clear: both;
    font-weight: 500;
    color: #030305;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 13px; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item svg {
    width: 18px;
    height: 18px;
    margin-left: 4px;
    vertical-align: bottom;
    color: #888ea8; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item:hover svg {
    color: #2196f3; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item.active, .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item:active {
    background-color: transparent;
    color: #3b3f5c;
    font-weight: 700; }
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item:hover {
    color: #2196f3; }

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-success.dropdown-toggle:focus {
    box-shadow: none; }

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-info.dropdown-toggle:focus {
    box-shadow: none; }

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: none; }

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: none; }

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: none; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: none; }

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: none; }

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: none; }

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: none; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: none; }

.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: none; }

.btn.focus, .btn:focus {
    box-shadow: none; }

.btn-success:focus, .btn-info:focus, .btn-danger:focus, .btn-warning:focus, .btn-secondary:focus, .btn-dark:focus, .btn-outline-success:focus, .btn-outline-info:focus, .btn-outline-danger:focus, .btn-outline-warning:focus, .btn-outline-secondary:focus, .btn-outline-dark:focus .btn-light-default:focus, .btn-light-primary:focus, .btn-light-success:focus, .btn-light-info:focus, .btn-light-danger:focus, .btn-light-warning:focus, .btn-light-secondary:focus, .btn-light-dark:focus {
    box-shadow: none; }

/*      Default Buttons       */
.btn-primary {
    color: #fff !important;
    background-color: #4361ee !important;
    border-color: #4361ee;
    box-shadow: 0 10px 20px -10px #4361ee; }
.btn-primary:hover, .btn-primary:focus {
    color: #fff !important;
    background-color: #4361ee;
    box-shadow: none;
    border-color: #4361ee; }
.btn-primary:active, .btn-primary.active {
    background-color: #4361ee;
    border-top: 1px solid #4361ee; }
.btn-primary.disabled, .btn-primary.btn[disabled], .btn-primary:disabled {
    background-color: #4361ee;
    border-color: #4361ee;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb; }
.btn-primary.focus:active {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb; }
.btn-primary:active:focus, .btn-primary:active:hover {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb; }

.open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #1a73e9;
    border-color: #1a73e9; }

.show > .btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #1a73e9;
    border-color: #1a73e9; }

.btn-primary .caret {
    border-top-color: #fff; }

.btn-group.open .btn-primary.dropdown-toggle {
    background-color: #eaf1ff; }

.btn-secondary {
    color: #fff !important;
    background-color: #805dca;
    border-color: #805dca;
    box-shadow: 0 10px 20px -10px #805dca; }
.btn-secondary:hover, .btn-secondary:focus {
    color: #fff !important;
    background-color: #805dca;
    box-shadow: none;
    border-color: #805dca; }
.btn-secondary:active, .btn-secondary.active {
    background-color: #805dca;
    border-top: 1px solid #805dca; }
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #805dca;
    border-color: #805dca; }

.show > .btn-secondary.dropdown-toggle {
    color: #fff !important;
    background-color: #805dca;
    border-color: #805dca; }

.btn-secondary.disabled, .btn-secondary.btn[disabled], .btn-secondary:disabled {
    background-color: #805dca;
    border-color: #805dca;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-secondary .caret {
    border-top-color: #fff; }

.btn-info {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3;
    box-shadow: 0 10px 20px -10px #2196f3; }
.btn-info:hover, .btn-info:focus {
    color: #fff !important;
    background-color: #2196f3;
    box-shadow: none;
    border-color: #2196f3; }
.btn-info:active, .btn-info.active {
    background-color: #2196f3;
    border-top: 1px solid #2196f3; }
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3; }

.show > .btn-info.dropdown-toggle {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3; }

.btn-info.disabled, .btn-info.btn[disabled], .btn-info:disabled {
    background-color: #2196f3;
    border-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3; }

.btn-info.focus:active {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3; }

.btn-info:active:focus, .btn-info:active:hover {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3; }

.open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3; }

.btn-info .caret {
    border-top-color: #fff; }

.btn-group.open .btn-info.dropdown-toggle {
    background-color: #e7f7ff; }

.btn-warning {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f;
    box-shadow: 0 10px 20px -10px #e2a03f; }
.btn-warning:hover, .btn-warning:focus {
    color: #fff !important;
    background-color: #e2a03f;
    box-shadow: none;
    border-color: #e2a03f; }
.btn-warning:active, .btn-warning.active {
    background-color: #e2a03f;
    border-top: 1px solid #e2a03f; }
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active {
    color: #0e1726;
    background-color: #e2a03f;
    border-color: #e2a03f; }

.show > .btn-warning.dropdown-toggle {
    color: #0e1726;
    background-color: #e2a03f;
    border-color: #e2a03f; }

.btn-warning.disabled, .btn-warning.btn[disabled], .btn-warning:disabled {
    background-color: #e2a03f;
    border-color: #e2a03f;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f; }

.btn-warning.focus:active {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f; }

.btn-warning:active:focus, .btn-warning:active:hover {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f; }

.open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f; }

.btn-warning .caret {
    border-top-color: #fff; }

.btn-group.open .btn-warning.dropdown-toggle {
    background-color: #df8505; }

.btn-danger {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a;
    box-shadow: 0 10px 20px -10px #e7515a; }
.btn-danger:hover, .btn-danger:focus {
    color: #fff !important;
    background-color: #e7515a;
    box-shadow: none;
    border-color: #e7515a; }
.btn-danger:active, .btn-danger.active {
    background-color: #e7515a;
    border-top: 1px solid #e7515a; }
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a; }

.show > .btn-danger.dropdown-toggle {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a; }

.btn-danger.disabled, .btn-danger.btn[disabled], .btn-danger:disabled {
    background-color: #e7515a;
    border-color: #e7515a;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.btn-danger.focus:active {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.btn-danger:active:focus, .btn-danger:active:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.btn-danger .caret {
    border-top-color: #fff; }

.btn-group.open .btn-danger.dropdown-toggle {
    background-color: #a9302a; }

.btn-dark {
    color: #fff !important;
    background-color: #3b3f5c;
    border-color: #3b3f5c;
    box-shadow: 0 10px 20px -10px #3b3f5c; }
.btn-dark:hover, .btn-dark:focus {
    color: #fff !important;
    background-color: #3b3f5c;
    box-shadow: none;
    border-color: #3b3f5c; }
.btn-dark:active, .btn-dark.active {
    background-color: #3b3f5c;
    border-top: 1px solid #3b3f5c; }
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #3b3f5c;
    border-color: #3b3f5c; }

.show > .btn-dark.dropdown-toggle {
    color: #fff !important;
    background-color: #3b3f5c;
    border-color: #3b3f5c; }

.btn-dark.disabled, .btn-dark.btn[disabled], .btn-dark:disabled {
    background-color: #3b3f5c;
    border-color: #3b3f5c;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-dark .caret {
    border-top-color: #fff; }

.btn-group.open .btn-dark.dropdown-toggle {
    background-color: #484848; }

.btn-success {
    color: #fff !important;
    background-color: #1abc9c;
    border-color: #1abc9c;
    box-shadow: 0 10px 20px -10px #1abc9c; }
.btn-success:hover, .btn-success:focus {
    color: #fff !important;
    background-color: #1abc9c;
    box-shadow: none;
    border-color: #1abc9c; }
.btn-success:active, .btn-success.active {
    background-color: #1abc9c;
    border-top: 1px solid #1abc9c; }
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #1abc9c;
    border-color: #1abc9c; }

.show > .btn-success.dropdown-toggle {
    color: #fff !important;
    background-color: #1abc9c;
    border-color: #1abc9c; }

.btn-success.disabled, .btn-success.btn[disabled], .btn-success:disabled {
    background-color: #1abc9c;
    border-color: #1abc9c;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678; }

.btn-success.focus:active {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678; }

.btn-success:active:focus, .btn-success:active:hover {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678; }

.open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678; }

.btn-success .caret {
    border-top-color: #fff; }

/*-----/Button Light Colors------*/
/*      Light Buttons       */
.btn-light-primary {
    color: #4361ee !important;
    background-color: #eaf1ff !important;
    border-color: #eaf1ff;
    box-shadow: 0 10px 20px -10px #eaf1ff; }
.btn-light-primary:hover, .btn-light-primary:focus {
    color: #4361ee !important;
    background-color: #e7f7ff;
    box-shadow: none;
    border-color: #e7f7ff; }
.btn-light-primary:active, .btn-light-primary.active {
    background-color: #eaf1ff;
    border-top: 1px solid #eaf1ff; }
.btn-light-primary.disabled, .btn-light-primary.btn[disabled], .btn-light-primary:disabled {
    background-color: #eaf1ff;
    border-color: #eaf1ff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
.btn-light-primary.active.focus, .btn-light-primary.active:focus, .btn-light-primary.active:hover {
    color: #fff !important;
    background-color: #eaf1ff;
    border-color: #eaf1ff; }
.btn-light-primary.focus:active {
    color: #fff !important;
    background-color: #eaf1ff;
    border-color: #eaf1ff; }
.btn-light-primary:active:focus, .btn-light-primary:active:hover {
    color: #fff !important;
    background-color: #eaf1ff;
    border-color: #eaf1ff; }

.open > .dropdown-toggle.btn-light-primary.focus, .open > .dropdown-toggle.btn-light-primary:focus, .open > .dropdown-toggle.btn-light-primary:hover {
    color: #fff !important;
    background-color: #eaf1ff;
    border-color: #eaf1ff; }

.btn-light-primary:not(:disabled):not(.disabled).active, .btn-light-primary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #4361ee;
    border-color: #4361ee; }

.show > .btn-light-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #4361ee;
    border-color: #4361ee; }

.btn-light-primary .caret {
    border-top-color: #fff; }

.btn-light-group.open .btn-light-primary.dropdown-toggle {
    background-color: #eaf1ff; }

.btn-light-secondary {
    color: #805dca !important;
    background-color: #f3effc;
    border-color: #f3effc;
    box-shadow: 0 10px 20px -10px #f3effc; }
.btn-light-secondary:hover, .btn-light-secondary:focus {
    color: #805dca !important;
    background-color: #f3effc;
    box-shadow: none;
    border-color: #f3effc; }
.btn-light-secondary:active, .btn-light-secondary.active {
    background-color: #f3effc;
    border-top: 1px solid #f3effc; }
.btn-light-secondary:not(:disabled):not(.disabled).active, .btn-light-secondary:not(:disabled):not(.disabled):active {
    color: #805dca !important;
    background-color: #f3effc;
    border-color: #f3effc; }

.show > .btn-light-secondary.dropdown-toggle {
    color: #805dca !important;
    background-color: #f3effc;
    border-color: #f3effc; }

.btn-light-secondary.disabled, .btn-light-secondary.btn[disabled], .btn-light-secondary:disabled {
    background-color: #f3effc;
    border-color: #f3effc;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-light-secondary .caret {
    border-top-color: #fff; }

.btn-light-info {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    border-color: #e7f7ff;
    box-shadow: 0 10px 20px -10px #e7f7ff; }
.btn-light-info:hover, .btn-light-info:focus {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    box-shadow: none;
    border-color: #e7f7ff; }
.btn-light-info:active, .btn-light-info.active {
    background-color: #e7f7ff;
    border-top: 1px solid #e7f7ff; }
.btn-light-info:not(:disabled):not(.disabled).active, .btn-light-info:not(:disabled):not(.disabled):active {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    border-color: #e7f7ff; }

.show > .btn-light-info.dropdown-toggle {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    border-color: #e7f7ff; }

.btn-light-info.disabled, .btn-light-info.btn[disabled], .btn-light-info:disabled {
    background-color: #e7f7ff;
    border-color: #e7f7ff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-light-info.active.focus, .btn-light-info.active:focus, .btn-light-info.active:hover {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    border-color: #e7f7ff; }

.btn-light-info.focus:active {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    border-color: #e7f7ff; }

.btn-light-info:active:focus, .btn-light-info:active:hover {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    border-color: #e7f7ff; }

.open > .dropdown-toggle.btn-light-info.focus, .open > .dropdown-toggle.btn-light-info:focus, .open > .dropdown-toggle.btn-light-info:hover {
    color: #2196f3 !important;
    background-color: #e7f7ff;
    border-color: #e7f7ff; }

.btn-light-info .caret {
    border-top-color: #fff; }

.btn-light-group.open .btn-light-info.dropdown-toggle {
    background-color: #e7f7ff; }

.btn-light-warning {
    color: #e29f3f !important;
    background-color: #fff9ed;
    border-color: #fff9ed;
    box-shadow: 0 10px 20px -10px #fff9ed; }
.btn-light-warning:hover, .btn-light-warning:focus {
    color: #e29f3f !important;
    background-color: #fff9ed;
    box-shadow: none;
    border-color: #fff9ed; }
.btn-light-warning:active, .btn-light-warning.active {
    background-color: #fff9ed;
    border-top: 1px solid #fff9ed; }
.btn-light-warning:not(:disabled):not(.disabled).active, .btn-light-warning:not(:disabled):not(.disabled):active {
    color: #0e1726;
    background-color: #fff9ed;
    border-color: #fff9ed; }

.show > .btn-light-warning.dropdown-toggle {
    color: #0e1726;
    background-color: #fff9ed;
    border-color: #fff9ed; }

.btn-light-warning.disabled, .btn-light-warning.btn[disabled], .btn-light-warning:disabled {
    background-color: #fff9ed;
    border-color: #fff9ed;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-light-warning.active.focus, .btn-light-warning.active:focus, .btn-light-warning.active:hover {
    color: #e29f3f !important;
    background-color: #fff9ed;
    border-color: #fff9ed; }

.btn-light-warning.focus:active {
    color: #e29f3f !important;
    background-color: #fff9ed;
    border-color: #fff9ed; }

.btn-light-warning:active:focus, .btn-light-warning:active:hover {
    color: #e29f3f !important;
    background-color: #fff9ed;
    border-color: #fff9ed; }

.open > .dropdown-toggle.btn-light-warning.focus, .open > .dropdown-toggle.btn-light-warning:focus, .open > .dropdown-toggle.btn-light-warning:hover {
    color: #e29f3f !important;
    background-color: #fff9ed;
    border-color: #fff9ed; }

.btn-light-warning .caret {
    border-top-color: #fff; }

.btn-light-group.open .btn-light-warning.dropdown-toggle {
    background-color: #df8505; }

.btn-light-danger {
    color: #e7515a !important;
    background-color: #fff5f5;
    border-color: #fff5f5;
    box-shadow: 0 10px 20px -10px #fff5f5; }
.btn-light-danger:hover, .btn-light-danger:focus {
    color: #e7515a !important;
    background-color: #fff5f5;
    box-shadow: none;
    border-color: #fff5f5; }
.btn-light-danger:active, .btn-light-danger.active {
    background-color: #fff5f5;
    border-top: 1px solid #fff5f5; }
.btn-light-danger:not(:disabled):not(.disabled).active, .btn-light-danger:not(:disabled):not(.disabled):active {
    color: #e7515a !important;
    background-color: #fff5f5;
    border-color: #fff5f5; }

.show > .btn-light-danger.dropdown-toggle {
    color: #e7515a !important;
    background-color: #fff5f5;
    border-color: #fff5f5; }

.btn-light-danger.disabled, .btn-light-danger.btn[disabled], .btn-light-danger:disabled {
    background-color: #fff5f5;
    border-color: #fff5f5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-light-danger.active.focus, .btn-light-danger.active:focus, .btn-light-danger.active:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.btn-light-danger.focus:active {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.btn-light-danger:active:focus, .btn-light-danger:active:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.open > .dropdown-toggle.btn-light-danger.focus, .open > .dropdown-toggle.btn-light-danger:focus, .open > .dropdown-toggle.btn-light-danger:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00; }

.btn-light-danger .caret {
    border-top-color: #fff; }

.btn-light-group.open .btn-light-danger.dropdown-toggle {
    background-color: #a9302a; }

.btn-light-dark {
    color: #3b3f5c !important;
    background-color: #e3e4eb;
    border-color: #e3e4eb;
    box-shadow: 0 10px 20px -10px #e3e4eb; }
.btn-light-dark:hover, .btn-light-dark:focus {
    color: #3b3f5c !important;
    background-color: #e3e4eb;
    box-shadow: none;
    border-color: #e3e4eb; }
.btn-light-dark:active, .btn-light-dark.active {
    background-color: #e3e4eb;
    border-top: 1px solid #e3e4eb; }
.btn-light-dark:not(:disabled):not(.disabled).active, .btn-light-dark:not(:disabled):not(.disabled):active {
    color: #3b3f5c !important;
    background-color: #e3e4eb;
    border-color: #e3e4eb; }

.show > .btn-light-dark.dropdown-toggle {
    color: #3b3f5c !important;
    background-color: #e3e4eb;
    border-color: #e3e4eb; }

.btn-light-dark.disabled, .btn-light-dark.btn[disabled], .btn-light-dark:disabled {
    background-color: #e3e4eb;
    border-color: #e3e4eb;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-light-dark .caret {
    border-top-color: #fff; }

.btn-light-group.open .btn-light-dark.dropdown-toggle {
    background-color: #484848; }

.btn-light-success {
    color: #1abc9c !important;
    background-color: #ddf5f0;
    border-color: #ddf5f0;
    box-shadow: 0 10px 20px -10px #ddf5f0; }
.btn-light-success:hover, .btn-light-success:focus {
    color: #1abc9c !important;
    background-color: #ddf5f0;
    box-shadow: none;
    border-color: #ddf5f0; }
.btn-light-success:active, .btn-light-success.active {
    background-color: #ddf5f0;
    border-top: 1px solid #ddf5f0; }
.btn-light-success:not(:disabled):not(.disabled).active, .btn-light-success:not(:disabled):not(.disabled):active {
    color: #1abc9c !important;
    background-color: #ddf5f0;
    border-color: #ddf5f0; }

.show > .btn-light-success.dropdown-toggle {
    color: #1abc9c !important;
    background-color: #ddf5f0;
    border-color: #ddf5f0; }

.btn-light-success.disabled, .btn-light-success.btn[disabled], .btn-light-success:disabled {
    background-color: #ddf5f0;
    border-color: #ddf5f0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-light-success.active.focus, .btn-light-success.active:focus, .btn-light-success.active:hover {
    color: #1abc9c !important;
    background-color: #17c678;
    border-color: #17c678; }

.btn-light-success.focus:active {
    color: #1abc9c !important;
    background-color: #17c678;
    border-color: #17c678; }

.btn-light-success:active:focus, .btn-light-success:active:hover {
    color: #1abc9c !important;
    background-color: #17c678;
    border-color: #17c678; }

.open > .dropdown-toggle.btn-light-success.focus, .open > .dropdown-toggle.btn-light-success:focus, .open > .dropdown-toggle.btn-light-success:hover {
    color: #1abc9c !important;
    background-color: #17c678;
    border-color: #17c678; }

.btn-light-success .caret {
    border-top-color: #fff; }

.btn.box-shadow-none {
    border: none; }
.btn.box-shadow-none:hover, .btn.box-shadow-none:focus {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent; }

.box-shadow-none {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; }

.btn.box-shadow-none:not(:disabled):not(.disabled).active, .btn.box-shadow-none:not(:disabled):not(.disabled):active {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent; }

.show > .btn.box-shadow-none.dropdown-toggle {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent; }

.btn-group.open .btn-success.dropdown-toggle {
    background-color: #499249; }

.btn-dismiss {
    color: #0e1726;
    background-color: #fff !important;
    border-color: #fff;
    padding: 3px 7px; }
.btn-dismiss:hover, .btn-dismiss:focus {
    color: #0e1726;
    background-color: #fff; }
.btn-dismiss:active, .btn-dismiss.active {
    background-color: #fff;
    border-top: 1px solid #fff; }

.btn-group > .btn i {
    margin-left: 3px; }

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12); }

.btn-group > .btn + .dropdown-toggle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
.btn-group > .btn + .dropdown-toggle.btn-primary {
    border-right: 1px solid #4468fd; }
.btn-group > .btn + .dropdown-toggle.btn-success {
    border-right: 1px solid #a3c66f; }
.btn-group > .btn + .dropdown-toggle.btn-info {
    border-right: 1px solid #49acfb; }
.btn-group > .btn + .dropdown-toggle.btn-warning {
    border-right: 1px solid #f5b455; }
.btn-group > .btn + .dropdown-toggle.btn-danger {
    border-right: 1px solid #f1848b; }
.btn-group > .btn + .dropdown-toggle.btn-dark {
    border-right: 1px solid #70767a; }
.btn-group > .btn + .dropdown-toggle.btn-secondary {
    border-right: 1px solid #8353dc; }

.btn-group.dropleft .dropdown-toggle-split {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.btn-group.dropleft .btn-primary:not(.dropdown-toggle-split) {
    border-right: 1px solid #4468fd; }

.btn-group.dropleft .btn-success:not(.dropdown-toggle-split) {
    border-right: 1px solid #a3c66f; }

.btn-group.dropleft .btn-info:not(.dropdown-toggle-split) {
    border-right: 1px solid #49acfb; }

.btn-group.dropleft .btn-warning:not(.dropdown-toggle-split) {
    border-right: 1px solid #f5b455; }

.btn-group.dropleft .btn-danger:not(.dropdown-toggle-split) {
    border-right: 1px solid #f1848b; }

.btn-group.dropleft .btn-dark:not(.dropdown-toggle-split) {
    border-right: 1px solid #70767a; }

.btn-group.dropleft .btn-secondary:not(.dropdown-toggle-split) {
    border-right: 1px solid #8353dc; }

/*
    Btn group dropdown-toggle
*/
.btn .badge.badge-align-right {
    position: absolute;
    top: -1px;
    left: 8px; }

.dropup .btn .caret {
    border-bottom-color: #0e1726; }

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #4361ee;
    color: #fff !important; }

.show > .btn-outline-primary.dropdown-toggle {
    background-color: #4361ee;
    color: #fff !important; }

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active {
    background-color: #1abc9c;
    color: #fff !important; }

.show > .btn-outline-success.dropdown-toggle {
    background-color: #1abc9c;
    color: #fff !important; }

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active {
    background-color: #2196f3;
    color: #fff !important; }

.show > .btn-outline-info.dropdown-toggle {
    background-color: #2196f3;
    color: #fff !important; }

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active {
    background-color: #e7515a;
    color: #fff !important; }

.show > .btn-outline-danger.dropdown-toggle {
    background-color: #e7515a;
    color: #fff !important; }

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active {
    background-color: #e2a03f;
    color: #fff !important; }

.show > .btn-outline-warning.dropdown-toggle {
    background-color: #e2a03f;
    color: #fff !important; }

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active {
    background-color: #805dca;
    color: #fff !important; }

.show > .btn-outline-secondary.dropdown-toggle {
    background-color: #805dca;
    color: #fff !important; }

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active {
    background-color: #3b3f5c;
    color: #fff !important; }

.show > .btn-outline-dark.dropdown-toggle {
    background-color: #3b3f5c;
    color: #fff !important; }

.show > .btn-outline-primary.dropdown-toggle:after, .show > .btn-outline-success.dropdown-toggle:after, .show > .btn-outline-info.dropdown-toggle:after, .show > .btn-outline-danger.dropdown-toggle:after, .show > .btn-outline-warning.dropdown-toggle:after, .show > .btn-outline-secondary.dropdown-toggle:after, .show > .btn-outline-dark.dropdown-toggle:after, .show > .btn-outline-primary.dropdown-toggle:before, .show > .btn-outline-success.dropdown-toggle:before, .show > .btn-outline-info.dropdown-toggle:before, .show > .btn-outline-danger.dropdown-toggle:before, .show > .btn-outline-warning.dropdown-toggle:before, .show > .btn-outline-secondary.dropdown-toggle:before, .show > .btn-outline-dark.dropdown-toggle:before {
    color: #fff !important; }

.btn-outline-primary {
    border: 1px solid #4361ee !important;
    color: #4361ee !important;
    background-color: transparent;
    box-shadow: none; }

.btn-outline-info {
    border: 1px solid #2196f3 !important;
    color: #2196f3 !important;
    background-color: transparent;
    box-shadow: none; }

.btn-outline-warning {
    border: 1px solid #e2a03f !important;
    color: #e2a03f !important;
    background-color: transparent;
    box-shadow: none; }

.btn-outline-success {
    border: 1px solid #1abc9c !important;
    color: #1abc9c !important;
    background-color: transparent;
    box-shadow: none; }

.btn-outline-danger {
    border: 1px solid #e7515a !important;
    color: #e7515a !important;
    background-color: transparent;
    box-shadow: none; }

.btn-outline-secondary {
    border: 1px solid #805dca !important;
    color: #805dca !important;
    background-color: transparent;
    box-shadow: none; }

.btn-outline-dark {
    border: 1px solid #3b3f5c !important;
    color: #3b3f5c !important;
    background-color: transparent;
    box-shadow: none; }

.btn-outline-primary:hover, .btn-outline-info:hover, .btn-outline-warning:hover, .btn-outline-success:hover, .btn-outline-danger:hover, .btn-outline-secondary:hover, .btn-outline-dark:hover {
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1); }

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #4361ee;
    box-shadow: 0 10px 20px -10px #4361ee; }

.btn-outline-info:hover {
    color: #fff !important;
    background-color: #2196f3;
    box-shadow: 0 10px 20px -10px #2196f3; }

.btn-outline-warning:hover {
    color: #fff !important;
    background-color: #e2a03f;
    box-shadow: 0 10px 20px -10px #e2a03f; }

.btn-outline-success:hover {
    color: #fff !important;
    background-color: #1abc9c;
    box-shadow: 0 10px 20px -10px #1abc9c; }

.btn-outline-danger:hover {
    color: #fff !important;
    background-color: #e7515a;
    box-shadow: 0 10px 20px -10px #e7515a; }

.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #805dca;
    box-shadow: 0 10px 20px -10px #805dca; }

.btn-outline-dark:hover {
    color: #fff !important;
    background-color: #3b3f5c;
    box-shadow: 0 10px 20px -10px #3b3f5c; }

/*      Dropdown Toggle       */
.btn-rounded {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important; }

.spin {
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear; }

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

.nav-link{
    color: inherit;
}
.nav-tabs .nav-link {
border: 0px;
}
.rdt_TableCol_Sortable span{
    font-size: 8px !important;
}
.caseSearch input{
    transition: .3s;
    width: 200px;
    outline: 0px !important;
    box-shadow: 0px 0px 0px !important;
    border: 0px !important;
    font-size: 13px;
}
.caseSearch input:focus{
    outline: 0px !important;
    box-shadow: 0px 0px 0px !important;
    border: 0px !important;
    width: 300px;
    font-size: 14px;
}
.caseSearch:hover svg{
    color: #4361ee;
}

.fs-6{
    font-size: 14px !important;
}
.table tr td{
    vertical-align: middle;
    font-weight: 400 !important;
}
.table.table-hover tr:hover{
    background-color: rgba(5, 171, 224, 0.03) !important;
}
.table-hover>tbody>tr:hover>*{
    --bs-table-accent-bg:none;
}
.table>:not(:first-child){
    border-top: 0px;
}
.btn-none,
.btn-none:hover,
.btn-none:focus{
    background-color: transparent;
    box-shadow: none;
    border: 0px;
    transform:none;
}


.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item{
}
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item:hover {
    color: #1a73e9 !important;
}
.status-dot{
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;;
    border-radius: 100%;
}
.active-border .nav-link:hover,
.active-border .nav-link:focus{
    background-color: transparent !important;
}
.active-border .nav-link.active {
    font-weight: 700;
}
.active-border .nav-link.active{
    border-bottom: 1px solid #0b2963;
}

.chats, .subChat {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.chats {
    overflow-y: auto;
    position: relative;
    padding-left: 5px !important;
}
.chats li.item:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 20px;
    right: -60px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #292F49;
    z-index: 1;
}
.imgChat {
    float: right;
    border-radius: 100%;
    overflow: hidden;
}
.ChatBody {
    float: left;
    width: calc(100% - 40px);
}
.ChatBody .txt1 {
    opacity: 0.5;
    font-size: 16px;
    color: #292F49;
    text-align: right;
    line-height: 21px;
}
.ChatBody .txt2 {
    font-size: 16px;
    color: #2E3654;
    text-align: right;
    line-height: 21px;
    margin-top: 5px;
    margin-bottom: 5px;
    opacity: 1;
}
.ChatBody .txt3 {
    font-size: 14px;
    color: #425278;
    text-align: right;
    line-height: 21px;
}
.chats li.item {
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
    padding-top: 20px;
    margin-right: 70px;
    position: relative;
 }
.chats li.item:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 20px;
    right: -48px;
    width: 1px;
    height: 100%;
    border-radius: 100%;
    background-color: #292F49;
    z-index: 0;
}
.chats li.item:last-child::after{
    display: none;
}
.chats li.unread:before {
    background-color: #fff;
    border: 1px solid #292F49;
    width: 24px;
    height: 24px;
}
.subChat {
    margin-right: 70px;
    margin-top: 10px;
}
.subChat li {
    margin-bottom: 5px;
}
.subChat li {
    background: rgba(41,47,73,0.05);
    padding: 10px;
    position: relative;
    border-radius: 5px;
}
.subChat li:first-child:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    right: 100px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent rgba(41,47,73,0.05) transparent;
}



.newBox {
    background: #FFFFFF;
    box-shadow: 0 9px 21px 0 rgba(88,91,111,0.08);
    border-radius: 10px;
    align-items: center;
    width: 100%;
    vertical-align: middle;
    overflow-y: -moz-scrollbars-horizontal;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
.task-card {
    background: #ffffff;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 2px;
    word-break: break-all;
    word-wrap: break-word;
    cursor: pointer;
    border: 1px solid rgba(151,151,151,0.1);
    box-shadow: 0 9px 20px -5px rgba(58,190,218,0.22);}
.list {
    list-style-type: none;
    padding-left: 0;
    border-radius: 2px;
    border-radius: 0px;
    margin: 10px;
}

.name-header {
    text-align: center;
    padding: 6px 6px;
}

.lists {
    background: #fff;
    box-shadow: 0 2px 7px 0 rgba(25,26,57,0.09);
    overflow-y: hidden;
    overflow-x: hidden;
}
.lists:hover{
    overflow-y: overlay;
}
.list-wrapper {
    flex-basis: 20%;
    width: 300px;
    margin: 10px;
    border-radius: 2px;
}


.kanban{
    height: calc(100vh - 230px) !important;
}
.kanbanCounter{
    background-color: #fff;
    display: inline-flex;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    text-align: center;
    color: #000;
    border-radius: 100%;
    margin-right: 5px;
    font-size: 14px;
     font-weight: bold;
    justify-content: center;
    padding-top: 3px;
}
.kanbanDiv{
    overflow-x: hidden;
}
.kanbanDiv:hover{
    overflow-x: auto;
}
.kanbanDiv::-webkit-scrollbar,
.kanbanDiv ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    border-radius: 5px;
}
.kanbanDiv::-webkit-scrollbar-track ,
.kanbanDiv ::-webkit-scrollbar-track {
    background: transparent;
}
.kanbanDiv::-webkit-scrollbar-thumb,
.kanbanDiv ::-webkit-scrollbar-thumb{
    background: rgba(41, 47, 73, 0.5);
    transition: .3s;
}
.kanbanDiv::-webkit-scrollbar-thumb:hover,
.kanbanDiv ::-webkit-scrollbar-thumb:hover{
    background: rgba(41, 47, 73, 0.8);
}

.kanBanItemHeader{
    font-size: 12px;
    vertical-align: middle;
    border-bottom: 1px solid rgba(151,151,151,0.1);
    margin-bottom: 4px;
}

.changeStaus label input {
    display: none;
}
.changeStaus {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}
.changeStaus span {
    font-size: 15px;
    color: #000000;
    text-align: right;
    display: inline-block;;
}
.changeStaus .status-dot{
    width: 7px;
    height: 7px;
}
.changeStaus .statusLabel {
    display: block;
    border: 1px solid #2A2F4E;
    border-radius: 3px;
    padding:5px;
    line-height: 1;
    margin-right: 5px;
    font-size: 14px;
    text-align: right;
    opacity: 0.7;
    color: #2E3654;
    font-weight: normal;
    transition: .1s;
    cursor: pointer;
}
.changeStaus input:checked ~ .statusLabel{
    background-color: #363C5D !important;
    color: #ffffff !important;
    opacity: 1;
}
.gBox {
    background: #FBFBFB;
    box-shadow: 0 2px 10px 0 rgba(25 ,26 ,57,0.09);
    padding: 30px 30px;
    transition: .2s;
    margin-bottom: 20px;
}
.gBox2 {
    padding: 10px 15px 15px 10px;
}
.gBoxHeader {
    border-bottom: 1px solid rgba(151,151,151,0.19);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 20px;
}
.gBoxBody {
    font-size: 22px;
    color: #535353;
    letter-spacing: 0;
    line-height: 29px;
    min-height: 100px;
}
.slaCanvas{
    border-radius: 40px 40px 0px  0px ;
    min-height: 450px !important;
}

.slaCanvas .offcanvas-body{
}
.slaCanvas .offcanvas-body .dropdown-toggle{
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
    position: relative;
    font-weight: bold;
}
.slaCanvas .offcanvas-body .dropdown-toggle:after{
    display: none;
}

.slaCanvas .offcanvas-body .dropdown-toggle:before{
    content: " ";
    display: block;
    bottom: 0px;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #999999;
}
.slaCanvas .offcanvas-body .dropdown-menu{
    color: #000;
    font-weight: bold;
    font-size: 14px;
}
.slaCanvas .offcanvas-body .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show{
    top: 35px !important;
}

.dropdown-menu .message .form-control:focus{
    box-shadow: 0px 0px 0px !important;
}
.fixedH.dropdown .dropdown-menu.show{
    max-height: 190px !important;
    overflow-y: scroll;
}
.btn{
 }

html[dir='ltr'] .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
    margin-right: inherit;;
}
.ql-container{
    font-family: inherit !important;
    font-size: 15px !important;
}
.tabsModal{
    background-color: #f7f7f7;
    margin-top: -20px;
}

.modal-content .nav-tabs button:focus{
    outline: 0px !important;
    box-shadow: none !important;
}
.sun-editor .se-toolbar{
    direction: ltr !important;
}
.sun-editor,.sun-editor-editable{
    font-family: inherit !important;
    font-size: 15px !important;
}
.react-aborist{
}
.row-contents {
    align-items: center;
    border: 2px solid transparent;
    border-radius: 0px;
    display: flex;
    font-size: 13px;
    height: 45px;
    height: 100%;
    padding-left: 30px;
    width: 100%;
    transition: .3s;
    border-bottom: 1px solid rgba(11, 41, 99, 0.16);
}
.row-contents button {
    align-items: center !important;
    background: none !important;
    border: none !important;;
    display: flex ;
    padding: 0 !important;;
    width: 14px !important;;
}

.row-contents .spacer {
    width: 14px;
}

.row-contents:hover{
    background-color: rgba(11, 41, 99, 0.14);
}
.react-aborist{
    direction: rtl !important;
    height: 100% !important;
}
