body {
    background: #F8FDFF;
}
.formDiv{
    margin-left: 10%;
    margin-right: 10%;
}
.logoDiv img {
    height: 75px;
}
.form-control,
.form-select{
    outline: none;
    height: 40px;
    box-shadow: 0px 2px 8px #eee;
    border: 1px solid #979797;
    border-radius: 5px;
    font-size: 18px;
    color: #727172;
}
@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.animate__slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}
form{
    /*
    animation: slideInUp;
    animation-duration: 0.7s;
    */
}