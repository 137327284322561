@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rubik');
@font-face {
    font-family: 'AlmoniDLAAA';
    src: url('fonts/AlmoniDLAAA-Bold.woff2') format('woff2'), url('fonts/AlmoniDLAAA-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AlmoniDLAAA';
    src: url('fonts/AlmoniDLAAA-Black.woff2') format('woff2'), url('fonts/AlmoniDLAAA-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AlmoniDLAAA';
    src: url('fonts/AlmoniDLAAA-Light.woff2') format('woff2'), url('fonts/AlmoniDLAAA-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AlmoniDLAAA';
    src: url('fonts/AlmoniDLAAA.woff2') format('woff2'), url('fonts/AlmoniDLAAA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    padding: 0;
    font-family: Tajawal,AlmoniDLAAA !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

