body,
html{
    position: relative;
    height: 100%;
}
.ServiceBg {
    background: url(../../../public/images/loginBG.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 40%;
    height: 100%;
    z-index: 0;
    display: block;;
}
header svg{
    height: 19px;
}
#home .bg:after{
    content: " ";
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#seriveProfile img{
    background-color: #537895;
    background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);    box-shadow: 0 12px 26px 0 rgba(3,3,3,0.26);
}
#seriveProfile img{
    width: 35px;
    height: 35px;
    border-radius: 35px;
    padding: 2px;
}
.dropdown-toggle::after{
    margin-right: 5px;
}
.dropdown button{
    padding: 0px;
    line-height: 1;
}
.icon{
    font-size: 18px;
    color: #0b2963;
}

.notifcation .budge {
    padding-top: 1px;
    font-size: 12px;
    background-color: crimson;
    border-radius: 20px;
    width: 15px;
    height: 15px;
    text-align: center;
    color: #fff;
    position: absolute;
}
.shake {
    animation: shake .5s;
    animation-iteration-count: infinite;
}
@keyframes shake {
    0% { transform:  rotate(0deg); }
    50% { transform:  rotate(-10deg); }
    100% { transform:  rotate(10deg); }
}
header .btn:focus{
    outline: 0;
    box-shadow: 0 0 0 0px;
    border: 0px;
}
.ServiceBody{
    height: calc(100% - 44px);
    position: relative;
    z-index: 3;
}
.owl-theme .owl-dots{
    text-align: center;
    margin-top: 20px;
    direction: ltr;
    /* margin-right: 10px; */
}
.owl-theme .owl-dots button{
    outline: none;
}
.owl-theme .owl-dots .owl-dot span{
    width: 10px;
    height: 10px;
    background: #8F96A9 ;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span{
    background: #537895 !important; ;
}
#Services .owl-carousel{
    vertical-align: middle;
}
#Services .owl-carousel .box{
    height: 150px;
    margin-bottom: 20px;
    margin-top: 5px;
    -webkit-box-shadow:0 6px 12px rgba(0,0,0,.175);box-shadow:0 6px 12px rgba(0,0,0,.175);
    text-align:center;
    padding: 10px;
    border-radius: 7px;
    transition: .2s;
    background: white;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
#Services .owl-carousel .box .new{
    width: 0;
    height: 0;
    position: absolute;
    left: 0px;
    top: 0px !important;
    z-index: -1;
    border-top: 50px solid #58DA7A;
    border-right: 50px solid transparent;
    transition: .2s;
    color: #fff;
}
#Services .owl-carousel .box:hover .new{
    border-top: 50px solid rgba(255,255,255,.2);
    border-right: 50px solid transparent;
}
#Services .owl-carousel .new span{
    transform: rotate(-45deg);
    font-size: 14px;
    width: 100%;
    display: block;
    position: absolute;
    top: -52px;
    text-align: center;
    left: 29px;
}
#Services .owl-carousel .lock:after{
    stroke: #FFFFFF;
    content: url('../images/lock.svg');
    font-family: "icomoon";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 21px;
    height: 21px;
    border-radius: 15px;
    z-index: -1;
    background-color: #D7060A;
    font-size: 12px;
    color: #fff;
    padding-top: 2px;
}


#Services .owl-carousel .box img{
    max-height: 55px;
    transition: .3s;
}
#Services .owl-carousel .box .notactive{
    opacity: .3;
}
#Services .owl-carousel .box:hover{
    color: #fff;
}

#Services .owl-carousel .box:hover .notactive{
    opacity: .5;
}

#Services .owl-carousel .box:hover img{
    max-height: 50px;
    margin-top: -5px;
}
#Services .owl-carousel .box h4{
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0px;
}
#Services .owl-carousel .box:before{
    position: absolute;
    top: 50%;
    right: 50%;
    height: 0px;
    z-index: -1;
    width:  0%;
    opacity: 0;
    border-radius: 300px;
    content: " ";
    background-color: #537895;
    background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);    box-shadow: 0 12px 26px 0 rgba(3,3,3,0.26);
    transition: .2s;
}
#Services .owl-carousel .box:hover img{
    filter: brightness(0) invert(1);
}
#Services .owl-carousel .box:hover:before{
    position: absolute;
    height: 100%;
    width: 100%;
    content: " ";
    top: 0px;
    opacity: 1;
    right: 0px;
    border-radius: 0px;
    background-color: #537895;
    background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);    box-shadow: 0 12px 26px 0 rgba(3,3,3,0.26);
}
.owl-theme .owl-nav.disabled + .owl-dots{
    margin-top: 0px;
}
#Services .owl-carousel .box h4{
    font-size: 16px;
}

@media(max-height:730px){
    #home .owl-carousel .box{
        height: 20vh;
        min-height: 100px;
        margin-right: 0px;
    }
    #home .owl-carousel .box img{
        max-height: 45px;
        margin-top: 5px;
    }
    #home .owl-carousel .box:hover img{
        max-height: 45px;
        margin-top: 5px;
    }
    #home .logo img{
        max-height: 60px;
    }
}
@media(max-width:992px){
    #home .bg{
        width: 100%;
    }

    #home .h-100{
        height: 100%;
    }
    .mobile-center{
        text-align: center;
    }
    #home .mobile-white{
        color: #fff !important;
    }

    #home .forms:before{
        content: " ";
        background-color: rgba(0,0,0,0.7);
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
    }
    #home header .logo{
        margin-top: 30px;
    }

    #home footer{
        position: inherit;
        display: block;
        margin-top: -1px;
        bottom: inherit !important;
        z-index: 2;
        padding: 0px 10px;
        font-size: 13px;
        color: #999;
    }
    #home #Services{
        padding: 0px;

    }
    #home .vcenter{
        margin-bottom: -1px;
    }
    #home .services{
        height: calc(100% - 55px);
    }
}
@media(max-width:768px){

    .mobile-small{
        font-size: 14px;
    }
    .mobile-small2{
        font-size: 16px;
    }
    #home header{
        padding-right: 0px;
        font-size: 12px;
        margin-top: 0px;
    }



    #home .owl-carousel .box img{
        max-height: 30px;
        margin-top: 5px;
    }
    #home .owl-carousel .box:hover img{
        max-height: 30px;
        margin-top: 5px;
    }
    #home .owl-carousel .box h4{
        font-size: 12px;
        margin: 0px;
        padding: 0px;
        margin-top: 10px;
    }


    #home .owl-carousel .box{
        margin-bottom: 6px;
        height: 15% !important;
        -webkit-box-shadow:0 3px 5px rgba(0,0,0,.175);box-shadow:0 3px 5px rgba(0,0,0,.175);
    }
    .owl-theme .owl-dots{
        text-align: center;
    }

    #home .owl-carousel .lock:after{
        content: "\e907";
        font-family: "icomoon";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        border-radius: 15px;
        z-index: -1;
        background-color: #D7060A;
        font-size: 10px;
        color: #fff;
        padding-top: 2px;
    }

    #home .owl-carousel{
        background-color: #F8FDFF;
        border-radius: 20px 20px 0px 0px;
        padding: 20px 10px;

    }

    #home footer{
        color: #999;
        background-color: #F8FDFF;
    }

    #home .dropdown a{
        font-size: 13px;
    }
}
@media(max-width:760px){
    .owl-carousel .col-md-3,
    .owl-carousel .col-sm-3,
    .owl-carousel .col-xs-4{
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media(max-width:360px){
    #home footer{
        font-size: 12px;
    }

}

@media(max-width:320px){
    #home footer{
        font-size: 10px;
    }
    #home .dropdown a{
        font-size: 12px;
    }
}
@media screen and (orientation:landscape) and (max-width:992px) {

    #home .services{
        height:auto !important;
    }
}

.canvusMenu.offcanvas,
#SerivcePage .Menu{
    background: rgb(38,44,77) !important;
    background: linear-gradient(
            145deg
            , rgba(38,44,77,1) 19%, rgba(64,71,104,1) 100%)  !important;
    color: #fff;

}

.canvusMenu.offcanvas .MenuLogo{
    display: none !important;
}

#SerivcePage .Menu{
    width: 230px;
    height: 100vh;
    padding: 0px;
    margin: 0px;
     position: fixed;
    overflow-y: auto;
}
.feather{
    fill: rgba(38,44,77,0.2);
    stroke: rgba(38,44,77,1);
    transition: 0.2s;
}
a:hover .feather{
    fill: rgba(38,44,77,1);
    fill: rgba(38,44,77,0.1);
}
.feather.svg-white{
    fill: rgba(255,255,255,0.2);
    stroke: rgba(255,255,255,1);
}
header .feather{
    width: 20px !important;
    height: 20px !important;
}
header .feather-grid{
    width: 24px !important;
    height: 24px !important;
}
#SerivcePage .mainDiv{
    width: calc(100% - 230px);
    padding: 0px;
    margin: 0px;
    position: relative;
    float: left;
}
#SerivcePage .mainDiv header {
    font-size: 14px;
    z-index: 9;
    box-shadow: 0 1px 10px 0 rgb(50 50 50 / 20%);
    width: 100%;
    background-color: #fff;
}
@media (min-width: 1800px) {
    #SerivcePage .Menu {
        width: 270px;
    }
    #SerivcePage .mainDiv {
        width: calc(100% - 270px);
    }
}
.MenuLogo{
    width: 80px;
    height: 80px;
    background-color: #FFFFFF;
}
.searchServ {
    box-shadow: 0px 0px 0px;
    border: 1px solid #4D547F;
    background-color: transparent;
    width: 100%;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 16px;
    height: inherit;;
}
.searchServ:focus{
     background: #292F49;
    color: #fff;
}
.CRMMenu{
    border-radius: 0px;
}
.CRMMenu .list-group-item:first-child{
    border-top: 1px solid rgba(255,255,255,.1);
}
.CRMMenu .list-group-item{
    background-color: transparent;
    color: #FFFFFF;
    border: 0px;
    border-bottom: 1px solid rgba(255,255,255,.1);
    z-index: 1;
    padding: 0px;
}
.CRMMenu .list-group-item:before {
    position: absolute;
    display: block;
    content: "";
    right: 0px;
    top: 0px;
    width: 0%;
    height: 100%;
    transition: .3s;
    background: #292F49;
    z-index: -1;
}
.CRMMenu .list-group-item a{
    display: block;
    padding: 7px 10px;
    font-size: 14px !important;
}
.CRMMenu .list-group-item a {
    font-weight: 500;
}

.CRMMenu .list-group-item .list-group a {
font-weight: 400;
}

.CRMMenu .list-group-item:hover::before{
    width: 100%;
}
.CRMMenu .list-group-item .list-group{
    height: 0px;
    overflow: hidden;;
}
.CRMMenu .list-group-item.open:before {
    background: #292F49;
    width: 100%;
}
.CRMMenu .list-group-item.open .list-group{
    height: auto;
}
.CRMMenu .havesub:after {
    display: block;
    position: absolute;
    left: 5px;
    top: 10px;
    width: 30px;
    height: 30px;
    text-align: center;
    content: "▾";
    z-index: -1;
}
html[dir="ltr"] .CRMMenu .havesub:after {
    right: 5px;
    left: inherit;;
}
html[dir="ltr"] .dropdown-menu[data-bs-popper]{
    right: 0px;
    left: inherit;
}
html[dir="ltr"] .CRMMenu .list-group-item:before {
    left: 0px;
    right: inherit;;
}

html[dir="ltr"] .ServiceBg{
    left: inherit;
    right: 0px;
}
html[dir="ltr"]  #SerivcePage .mainDiv{
    float: right;
    left: inherit;
}

#SerivcePage .content{
    margin:   60px;
    margin-bottom: 20px;
}
#SerivcePage .box{
    box-shadow: 0px 5px 8px rgba(0,0,0,0.04);
    background-color: #FFFFFF;
    border: 1px solid #e2e2e2;
    padding: 20px;
    -webkit-animation: slit-in-horizontal 0.6s ease-out both;
    animation: slit-in-horizontal 0.6s ease-out both;
}
#SerivcePage .content .personalImage {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    margin: 0px auto;
    overflow: hidden;
    margin-top: -70px;
    background: linear-gradient(
            145deg
            , rgba(38,44,77,1) 19%, rgba(64,71,104,1) 100%)  !important;
    padding-top: 4px;
    box-shadow: 0px -4px 15px #4D547F;
}
#SerivcePage .content .personalImage img{
    width: 100%;
}
.nav-tabs .nav-item .nav-link, .nav-tabs .nav-link.active{
    background-color: transparent;
    font-size: 18px;
    color: #666;
}
.nav-tabs .nav-item .nav-link.show, .nav-tabs .nav-link.active {
    border-bottom: 1px solid #4D547F !important;
    color: #09203f;
    font-weight: bold;
}
.historyTable >thead> tr>td{
    color: #0b2963;
    font-weight: 700;
}
.historyTable >tbody> tr>td>span{
    display: block;
    color: #0b2963;
    font-weight: 700;
     margin: 0px;
    padding: 0px;
    line-height: 1.1;
    font-size: 15px;
    padding: 2px 0px;
}
.historyTable >tbody> tr>td>span:last-child {
    display: block;
    color: #BABABA;
    font-weight: normal;;
}
.historyTable >tbody> tr:hover{
    background-color: rgba(11,41,99,0.05);
}
.blueLink {
    color:#292F49 !important;
    transition: .2s;
}
.blueLink:hover{
    color:#D7060A !important;
 }
.AccountDetails{
    font-size: 17px;
}
.AccountDetails .row{
    position: relative;
}
.pagetitle{
    font-size: 20px;
    font-weight: 700;
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 500ms both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 500ms both;
}
.box .pagetitle{
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: normal;
}
.box hr{
    background-color: #8F96A9;
}
.dashboard .box .bold{
    color: #4D547F;
    font-size: 18px;
}

.lightbox {
    background-color: #fff;
    box-shadow: 2px 4px 10px 1px rgb(0 0 0 / 5%);
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.05);
    -webkit-animation: slit-in-horizontal 0.6s ease-out ;
    animation: slit-in-horizontal 0.6s ease-out ;
}
.newTab {
    border-bottom: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    display: block !important;
    width: 100%;
}

.newTab .nav-item .nav-link.show, .newTab .nav-link.active{
    border: 0px !important;
}
.newTab .nav-item{
    position: relative;
}
.newTab  .selector {
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    border-radius: 5px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background: rgb(38,44,77) !important;
    background: linear-gradient(
            145deg
            , rgba(38,44,77,1) 19%, rgba(64,71,104,1) 100%) !important;
}
.newTab .nav-link{
    display: block;
    position: relative;
}
.newTab .nav-link.text-white:hover{
    color: #ffffff !important;
}
.shodow-hr{
    background-color: #ccc;
 }
.newTab .nav-link{
    padding: 10px 20px;
    font-size: 16px;
}
.newTab .nav-item:hover{
    background-color: transparent !important;
    cursor: pointer;
}
.text-red{
    color: rgb(232, 66, 98);
}
.newTab .nav-item a.active{
    font-weight: 700;
}

.newTab .selector {
    background: -webkit-linear-gradient(right, #61da56, #36dd82, #00dda7, #00dcc3, #40d9d7);
    background: linear-gradient(to left, #61da56, #36dd82, #00dda7, #00dcc3, #40d9d7);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05abe0', endColorstr='#8200f4',GradientType=1 );
}

@media (max-width: 1200px) {
    #SerivcePage .content {
        margin: 50px 10px;
    }
    #SerivcePage .box {
        padding: 10px;
    }
}
@media (max-width: 991px) {
    #SerivcePage .content {
        padding: 10px;
        margin: 20px 10px;
    }
    #SerivcePage .mainDiv {
        width: 100%;
    }
    #SerivcePage .Menu{
        display: none;
    }
    #SerivcePage .content .personalImage{
        width: 70px;
        height: 70px;
        margin-top: -40px;
    }
}



.status{
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 15px);
    font-weight: normal;
    color: rgba(0,0,0,0.7);
}

.status:before{
    width:10px;
    height: 10px;
    border-radius: 10px;
    content: " ";
    display: inline-block;
    background-color: rgba(0,0,0,0.19);
    vertical-align: middle;
    margin-right: 5px;
}

.status.orange:before{
    background-color: rgba(248,122,0,1.00);
}
.status.red:before{
    background-color:rgba(249,62,65,1.00);
}
.status.green:before{
    background-color:rgba(18,186,71,1.00);
}


.lightbox .DarkBtn{
    padding: 5px 20px;
    border-radius: 20px;
    font-size: 13px;
    width: auto;
}
.btns.dropdown button{
    padding: 8px 30px;
}